body {
    color: black
}
.item-menu {
    margin-top: 10px
}
.item-menu ul.scrols {
    overflow: hidden;
    height: 114px
}
.item-menu a.vtou {
    color: #d33b38;
    font-size: 12px;
    float: right;
    display: inline-block;
    text-decoration: none;
    margin-top: 3px
}
.item-menu a.vtout:hover {
    text-decoration: underline
}
.block-social .icon-facebook {
    width: 70px;
    height: 40px;
    background-position: -50px -733px !important
}
.block-social .icon {
    background: url('../../public/img/sprites.png') no-repeat;
    display: inline-block;
    position: relative
}
.block-social .icon:before {
    content: ''
}
.block-social .icon-twitter {
    background-position: -67px -773px;
    height: 40px;
    width: 72px
}
.block-social .icon-twitter:before {
    content: ''
}
.block-social .icon-gplus {
    height: 40px;
    background-position: -116px -733px;
    width: 72px
}
.block-social .icon-gplus:before {
    content: ''
}
.block-social .icon-linkedin {
    height: 40px;
    background-position: 0 -773px;
    width: 72px
}
.address .icon {
    background: auto;
    display: inline-block;
    position: relative
}
.icon-wine-blanc-tranquille {
    background-position: -150px -575px !important;
    height: 38px;
    width: 23px
}
.icon-wine-rouge-tranquille {
    width: 23px;
    height: 38px;
    background-position: -150px -536px
}
.content {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
}
.content p {
    color: #000;
    font-size: 15px;
    margin: 1em 0 1em
}
.content h2 {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 26px;
    line-height: 30px;
    color: #333333;
    font-weight: 700;
}
.content h3 {
    font-size: 22px;
    line-height: 26px;
    color: #333333;
    font-weight: 600;
}
.icon {
    background: auto;
    background-size: inherit;
    display: inline-block;
    position: relative
}
.article-area .content {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.5em
}
.icon-arrow-left-red {
    width: 5px;
    height: 12px;
    background-position: -120px -240px
}
.icon-t-red {
    width: 18px;
    height: 20px;
    background-position: -127px -240px
}
.icon-printer-dark {
    height: 17px;
    background-position: -64px -267px;
    width: 20px
}
.icon-share-red {
    width: 23px;
    height: 17px;
    background-position: 0 -264px
}
.nav .share, .nav .favorite {
    font-size: 11px !important
}
.nav a.back {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
}
.nav .favorite {
    height: 38px;
    line-height: 32px
}
.people .icon {
    top: -2px
}
.favorite .icon {
    top: 0 !important
}
.nav .share {
    line-height: 27px
}
.share .icon {
    top: 0 !important
}
span.hidden-phone {
    font-size: 11px
}
/*.main-content h2 {
    color: #000;
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 1.75em
}*/
.icon-2-people-gray {
    height: 15px;
    background-position: -95px -411px;
    width: 18px
}
.icon-tilting-glass-dark {
    height: 25px;
    background-position: -112px -448px;
    width: 26px
}
.theme-area h2.block-title {
    font-weight: normal;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 2.2em;
    color: #3d3d3d;
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px
}
.icon-star-yellow {
    height: 10px;
    background-position: -87px -397px;
    width: 12px
}
.icon-star-light-gray {
    background-position: -110px -397px;
    height: 10px;
    width: 12px
}
.content .title a {
    font-size: 11px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
}
.content .name a {
    font-size: 16px;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
}
.rating .icon {
    top: -5px !important
}
.rating .icon-star-yellow {
    top: -2px !important
}
.rating .icon-star-light-gray {
    top: -2px !important
}
.people {
    font-size: 12px
}
.difficulty {
    font-size: 12px
}
.people .icon {
    top: -2px !important
}
.meta .people {
    top: -3px !important
}
.pagination {
    border-top: 0 !important;
    padding: 0 !important;
    text-align: right;
    float: right
}
.pagination li a {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    background: 0;
    color: #a9a9a9;
    height: inherit;
    width: 15px;
    font-size: 12px;
    padding: 10px 5px
}
.pagination li.active span {
    font-size: 12px;
    color: #000;
    padding: 0 !important;
    background: inherit !important
}
.pagination .disabled span {
    font-size: 12px
}
.pagination li a:hover {
    padding: 0;
    height: inherit;
    width: 15px
}
.pagination ul {
    text-align: left
}
.pagination ul li:hover a {
    color: black;
    padding: 0;
    background: inherit
}
.pagination li .icon-arrow-left-gray {
    width: 5px;
    height: 8px;
    background-position: -27px -282px
}
.pagination li .icon-arrow-right-gray {
    height: 8px;
    background-position: -35px -282px;
    width: 5px
}
.pagination ul li a,
.pagination ul li span {
    background: inherit !important;
    padding: 0 !important;
    height: inherit;
    width: inherit;
    margin-right: 6px
}
.header .title a {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 17px;
}
.meta .year {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 14px;
    top: 3px
}
.icon-star-red {
    width: 16px;
    height: 16px;
    background-position: -74px 0
}
.icon-star-gray {
    width: 16px;
    height: 16px;
    background-position: -74px -17px
}
.icon-circle-gray {
    width: 9px;
    height: 9px;
    background-position: -120px -129px
}
.icon-lock-black {
    background: url('../../public/img/sprites.png') no-repeat;
    background-position: -140px -105px;
    height: 17px;
    width: 14px
}
.icon-wine-blanc-effervescent {
    width: 14px;
    height: 38px;
    background-position: -230px -575px
}
.icon-lock-black {
    width: 14px;
    height: 17px;
    background-position: -140px -105px
}
.icon-heart-red {
    width: 23px;
    height: 21px;
    background-position: -32px -179px
}
.main-sidebar .nouveau {
    background: #d0e39c url("../../public/images/image-nouveau2.png") center left no-repeat;
    padding: 40px 15px 30px 150px;
    margin-bottom: 30px;
    position: relative
}
.main-sidebar .nouveau a span.title {
    font-family: 'BebasNeue';
    font-size: 38px;
    line-height: 34px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 20px;
    position: relative;
    display: inline-block
}
.main-sidebar .nouveau a span.title:after {
    content: '';
    display: block;
    background: #000;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px
}
.main-sidebar .nouveau .des {
    color: #1f1f1f;
    font-size: 16px
}
.main-sidebar .nouveau .des span {
    font-weight: bold;
    color: #a42636
}
.main-sidebar .nouveau a:hover .title {
    color: #ce2c4b
}
.main-sidebar .nouveau a:hover.main-sidebar {
    color: #ce2c4b
}
.main-sidebar .nouveau a:hover .title:after {
    content: '';
    display: block;
    background: #a62c39;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px
}
.main-sidebar .nouveau .title:after {
    content: '';
    display: block;
    background: #000;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px
}
.icon-glass-white2 {
    width: 20px;
    height: 22px;
    background-position: 0 -398px
}
.icon-x-black {
    background: url('../../public/img/sprites.png') no-repeat;
    background-position: -40px -27px;
    height: 14px;
    width: 14px
}
.inner .close {
    opacity: inherit
}
.icon-drink-gray {
    background: url('../../public/img/sprites.png') no-repeat;
    background-position: -75px -34px;
    height: 25px;
    width: 16px
}
.civility label::before {
    display: none !important
}
.civility label::after {
    display: none !important
}
.radio label {
    display: inline-block !important;
    font-weight: 700;
    margin-right: 0
}
.radio input[type="radio"] {
    opacity: inherit !important;
    float: left;
    margin: 0 5px 0 0 !important
}
.icon-grape2 {
    width: 17px;
    height: 21px;
    background-position: -178px 0
}
.icon-grape1 {
    width: 17px;
    height: 21px;
    background-position: -157px 0
}
.icon-wine-rose-tranquille {
    width: 23px;
    height: 38px;
    background-position: -204px -536px
}
.icon-wine-rose-effervescent {
    width: 14px;
    height: 38px;
    background-position: -230px -536px
}
.icon-sand-clock-dark {
    width: 13px;
    height: 17px;
    background-position: -22px -375px
}
.icon-star-black {
    width: 16px;
    height: 17px;
    background-position: -101px -159px
}
.icon-tag-black {
    width: 19px;
    height: 21px;
    background-position: 0 -39px
}
.icon-bio-green {
    width: 21px;
    height: 19px;
    background-position: 0 -375px
}
.icon-glass-dark {
    width: 20px;
    height: 22px;
    background-position: -21px -398px
}
.icon-circle-black {
    width: 9px;
    height: 9px;
    background-position: -118px -98px
}
.icon-plus-red {
    width: 12px;
    height: 12px;
    background-position: -40px -50px
}
.icon-minus-dark {
    width: 12px;
    height: 2px;
    background-position: -142px -379px
}
.blocks {
    font-size: 14px
}
.blocks .content {
    font-family: 'Raleway', sans-serif
}
.panels .block .content .title {
    color: black;
    padding: 0 !important;
    font-size: 20px
}
.blocks .filters .title h2 {
    font-weight: 500
}
.panels .block .content .title a {
    font-size: 20px !important
}
.blocks .filters .title h2 strong {
    font-weight: 700
}
.panels .block .content .sub-title {
    font-size: 15px;
    font-weight: 500;
    color: black;
    padding: 5px 0
}
.panels .block .content .sub-title h2 {
    font-weight: 500
}
.panels .block .content .stars h2 {
    font-size: 14px
}
.icon-tint {
    font-size: 12px
}
.note i {
    margin-top: -2px;
    padding-right: 3px
}
.icon-tint:before {
    content: '\e88f'
}
.icon-arrow-down-black-small {
    width: 12px;
    height: 7px;
    background-position: -26px -293px
}
.icon-mail-black {
    width: 19px;
    height: 15px;
    background-position: -91px -17px
}
.flex-next {
    top: 35%;
    right: 11px;
    background-position: -231px 0
}
.flex-prev {
    top: 35%;
    left: 10px;
    background-position: -246px 0
}
.icon-pencil-gray {
    width: 26px;
    height: 26px;
    background-position: -127px -34px
}
.icon-2-people {
    width: 27px;
    height: 27px;
    background-position: -92px -240px
}
.icon-clock-dark {
    width: 27px;
    height: 24px;
    background-position: -64px -240px
}
h1.main-title {
    font-weight: 600
}
.text-app-right .free {
    text-transform: none
}
.fblock {
    padding: 40px 15px 30px 23px !important;
    overflow: hidden
}
.fblock .blImg img {
    width: 100%
}
.fblock .blImg {
    float: left;
    width: 30%
}
.fblock .blTxt {
    float: right;
    width: 58%
}
.icon-drink-black {
    width: 12px;
    height: 20px;
    background-position: -140px -84px
}
.icon-heart-white-black {
    width: 26px;
    height: 22px;
    background-position: 0 -240px
}
h2.block-title {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 22px;
    font-weight: 600
}
.description {
    font-family: "PT Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif
}
.panels .block .content .title h2 {
    font-weight: 500
}
.panels .block .content .sub-title {
    padding: 8px 0 !important
}
.panels .block .content .sub-title h2 {
    font-size: 15px
}
.panels .block .content .stars h2 {
    float: left
}
.panels .block .content .stars i {
    font-size: 22px
}
.panels .block .content .wish h2 {
    float: right
}
.pagination {
    background: inherit;
    overflow: hidden;
    width: 100%;
    padding: 7px 0 !important;
    text-align: left
}
.pagination ul {
    background: inherit !important
}
.pagination ul li {
    font-weight: bold;
    font-size: 14px
}
.pagination ul li a {
    font-size: 14px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    display: inline-block;
    padding: 5px 0 !important;
    font-family: 'PT Sans', sans-serif;
    margin-right: inherit !important
}
.pagination ul li span {
    font-size: 14px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    display: inline-block;
    padding: 5px 0 !important;
    font-family: 'PT Sans', sans-serif
}
.pagination ul li.active {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    text-align: center;
    color: white;
    padding: 5px 0;
    background: black !important
}
.pagination ul li:hover a {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    color: white;
    padding: 5px 0;
    text-align: center;
    background: black
}
.pagination {
    padding: 7px 0 0 7px
}
.pagination ul li.suivant a {
    height: inherit;
    width: inherit;
    margin-left: 20px
}
.pagination ul li.suivant:hover a {
    background: none !important;
    color: #a62c39
}
.result-area .result-footer {
    margin-bottom: inherit !important
}
.header-recherche .block-banner {
    margin-top: 55px;
    margin-bottom: 20px
}
.block-collapse .collapse-ge .hr {
    border-bottom: 1px solid #e3e3e3;
    height: 1px
}
.history-area-full .block-action {
    margin-bottom: 0
}
.block-action .action-inner {
    font-size: 11px
}
.panels .block .images {
    width: 17%;
    padding-right: 2%
}
.panels .block .content {
    width: 70%
}
.filter-search span.twitter-typeahead {
    display: block !important;
    position: relative !important
}
.commentaire .title {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    color: black;
    padding: 10px 0
}
.commentaire .title h2 {
    color: black;
    font-weight: bold
}
.commentaire .title h2 strong {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    display: inline
}
.commentaire .comment-form {
    margin-bottom: 15px;
    background: #f4f4f4;
    padding: 20px;
    border: 1px solid #d7d8d8
}
.commentaire .comment-form .comment label {
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    display: inline-block
}
.commentaire .comment-form #commentaires_texte {
    position: relative;
    margin-top: 5px;
    padding: 6px 12px;
    font-size: 16px;
    font-family: 'PT Sans', sans-serif;
    border-radius: 0;
    border: 0;
    resize: none;
    background: #fff;
    height: 100px;
    width: 100%
}
.commentaire .comment {
    margin-bottom: 15px
}
.commentaire .comment-form {
    padding-bottom: 55px
}
.commentaire .comment-form .vote {
    height: 20px;
    line-height: 20px;
    margin-bottom: 15px
}
.commentaire .comment-form .vote label {
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    font-weight: bold
}
.commentaire .comment-form .vote .connectez-vous {
    display: inline-block
}
.commentaire .comment-form .vote .connectez-vous i {
    color: #dde1ea;
    font-size: 13px
}
.commentaire .comment-form .vote .connectez-vous i:before {
    margin-right: 0
}
.commentaire .comment-form .vote .connectez-vous i:first-child {
    margin-left: 10px
}
.commentaire .textArea {
    position: relative;
    margin-top: 5px
}
.commentaire .textArea:after {
    content: '\e853';
    display: block;
    position: absolute;
    top: -5px;
    font-family: 'fontello';
    left: 15px;
    font-size: 20px;
    height: 12px;
    line-height: 12px;
    color: #fff
}
.commentaire .action {
    text-align: right
}
.commentaire .action .submit {
    background: inherit;
    border: 0;
    background: #272727;
    text-transform: uppercase;
    font-size: 11px;
    font-family: 'PT Sans', sans-serif;
    color: #fff;
    padding: 10px 15px 10px 0;
    position: relative;
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #000004;
    float: right;
    -webkit-box-shadow: 0 1px 2px 0 rgba(157, 157, 157, 0.75);
    -moz-box-shadow: 0 1px 2px 0 rgba(157, 157, 157, 0.75);
    box-shadow: 0 1px 2px 0 rgba(157, 157, 157, 0.75);
    width: auto
}
.commentaire .connectez-vous i.icon-circle:first-child {
    margin-left: 10px
}
.form-default .radio label:before {
    display: none
}
.abonnement-area form .radio input {
    top: 7px !important
}
h2.block-title i.icon-eye-dark {
    width: 28px;
    height: 18px;
    background-position: -58px -400px
}
h2.block-title i.icon-lid-dark {
    width: 31px;
    height: 19px;
    background-position: -122px -330px
}
h2.block-title i.icon-location-alsace {
    width: 34px;
    height: 40px;
    background-position: 0 -913px
}
h2.block-title i.icon-region-alsace {
    width: 46px;
    height: 43px;
    background-position: -158px -304px
}
h2.block-title i.icon-location-alsace {
    width: 34px;
    height: 40px;
    background-position: 0 -913px
}
.block-social a.facebook,
.block-social a.twitter,
.block-social a.gplus,
.block-social a.linkedin {
    display: inline-block !important
}
h2.block-title i.icon-region-black {
    width: 27px;
    height: 29px;
    background-position: -42px -149px
}
span.image i.icon-location-alsace {
    width: 35px;
    height: 43px;
    background-position: 0 -912px
}
span.image i.icon-location-beaujolais-et-lyonnais {
    width: 35px;
    height: 40px;
    background-position: -36px -912px
}
span.image i.icon-location-bordelais {
    width: 35px;
    height: 40px;
    background-position: -71px -912px
}
span.image i.icon-location-bourgogne {
    width: 35px;
    height: 40px;
    background-position: -108px -912px
}
span.image i.icon-location-champagne {
    width: 35px;
    height: 40px;
    background-position: -143px -912px
}
span.image i.icon-location-corse {
    width: 35px;
    height: 40px;
    background-position: -180px -912px
}
span.image i.icon-location-jura {
    width: 35px;
    height: 40px;
    background-position: -216px -912px
}
span.image i.icon-location-languedoc {
    width: 35px;
    height: 40px;
    background-position: 0 -956px
}
span.image i.icon-location-lorraine {
    width: 35px;
    height: 40px;
    background-position: -36px -956px
}
span.image i.icon-location-poitou-charentes {
    width: 35px;
    height: 40px;
    background-position: -36px -956px
}
span.image i.icon-location-provence {
    width: 35px;
    height: 40px;
    background-position: -108px -956px
}
span.image i.icon-location-roussillon {
    width: 35px;
    height: 40px;
    background-position: -143px -956px
}
span.image i.icon-location-savoie-et-bugey {
    width: 35px;
    height: 40px;
    background-position: -180px -956px
}
span.image i.icon-location-sud-ouest {
    width: 35px;
    height: 40px;
    background-position: -216px -956px
}
span.image i.icon-location-vallee-de-la-loire-et-centre {
    width: 35px;
    height: 40px;
    background-position: 0 -999px
}
span.image i.icon-location-vallee-du-rhone {
    width: 35px;
    height: 40px;
    background-position: -36px -999px
}
span.image i.icon-location-vin-de-pays {
    width: 35px;
    height: 40px;
    background-position: -72px -998px
}
span.image i.icon-location-vins-du-luxembourg {
    width: 35px;
    height: 40px;
    background-position: -108px -998px
}
.commentaire .comments .comment {
    padding: 20px;
    margin-bottom: 15px;
    background: #f4f4f4;
    border-radius: 3px;
    color: #454545;
    font-size: 1.4em
}
.commentaire .comments .comment .notice {
    color: #666;
    margin-bottom: 10px
}
.commentaire .comments .comment .meta {
    font-weight: bold;
    margin-bottom: 10px
}
.commentaire .comments .comment .content {
    margin: 0;
    padding: 0;
    min-height: initial
}
.fiche-content-vin .main-sidebar {
    float: none
}
h2.block-title i.icon-location-red {
    width: 34px;
    height: 40px;
    background-position: 0 -491px
}
h2.block-title i.icon-tag-dark {
    width: 24px;
    height: 13px;
    background-position: -122px -350px
}
.icon-bottle-dark {
    width: 11px;
    height: 37px;
    background-position: -88px -295px
}
.icon-i-dark {
    width: 11px;
    height: 23px;
    background-position: -59px -287px
}
.icon-phone-black {
    width: 15px;
    height: 14px;
    background-position: -42px -284px
}
.icon-location-bourgogne {
    width: 34px;
    height: 40px;
    background-position: -108px -913px
}
.icon-region-bourgogne {
    width: 42px;
    height: 43px;
    background-position: -206px -352px !important
}
.icon-enlarge {
    width: 96px;
    height: 96px;
    background-position: -155px -24px
}
.icon-region-beaujolais-et-lyonnais {
    width: 42px;
    height: 43px;
    background-position: -206px -304px
}
.panels .block .content .stars h2 {
    line-height: 21px
}
span.rating span {
    display: inline-block;
    margin-right: 3px
}
span.stars span.rating .active i {
    color: #dd4a4d
}
span.stars span.rating i {
    color: #eaeaea;
    font-size: 22px;
    vertical-align: middle
}
.checkbox-general label.note-stars:before {
    top: 5px
}
.checkbox-general label.note-stars:after {
    top: 4px
}
.checkbox-general span.rating i.icon-heart {
    font-size: 25px;
    color: #dc4145;
    padding-right: 5px;
    vertical-align: middle
}
.accord-image {
    line-height: 145px
}
.block:after {
    content: none
}
.block .sub-title h2 {
    font-family: "Raleway"
}
.breadcrumbs {
    margin-top: 10px;
}
header .menulhg .minimal-menu ul li.megamenu .menu-wrapper .menu-col.menu-2-col2 .btn {
    margin-top: 40px
}
header .menulhg .minimal-menu ul li.megamenu.cuisine-vins .menu-wrapper .btn.btn-incontournables {
    margin-top: 10px
}
.menu-2-col1 a.btn {
    position: absolute;
    bottom: 0;
    left: 30px
}
.menu-2-col2 a.btn {
    position: absolute;
    bottom: -115px;
    left: 50px
}
.history-area-full .block .accord-image img {
    width: 100%
}
.history-area-full .block .content {
    margin-left: 19%
}
.block .prods {
    line-height: inherit;
    display: inline-block
}
.history-area-full .block .prods {
    line-height: inherit;
    display: inline-block
}
.panel .block .prod {
    padding-top: 0
}
.panel .block .prod {
    margin-left: 0;
    width: 73%
}
.history-area-full .block .content {
    margin-left: 0;
}
.voir-offre {
    text-align: center;
    text-decoration: underline
}
.oder.type-2 .achat-block .promotion {
    color: #ce2c4b !important;
    font-size: 12px;
    padding-top: 6px;
    text-transform: uppercase;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold
}
.section-home-achat-vins {
    padding: 20px 0 !important
}
header .menulhg .minimal-menu ul .slide-offer .list-offer .content-incontournables .right-content-incon .info .meta .rating .icon-star {
    display: inline-block;
    color: #a4a4a4;
    font-size: 17px;
    width: 16px;
    height: 16px;
    margin: 0
}
header .menulhg .minimal-menu ul .slide-offer .list-offer .content-incontournables .right-content-incon .info .meta .rating .icon.icon-star-red {
    width: 16px;
    color: #ce2c4b
}
header .menulhg .minimal-menu ul .slide-offer .list-offer .content-incontournables .right-content-incon .info .meta .rating .icon.icon-star-gray {
    width: 16px;
    color: #a4a4a4
}
.block-action .action-inner a {
    font-size: 13px
}
.result-area .panels .block {
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9
}
@media screen and (min-width: 320px) {
    .panels .block .prods {
        vertical-align: top;
        margin-left: 5%;
        margin-right: 5%;
        width: 8%
    }
}
@media screen and (min-width: 320px) {
    .result-area .panels .block {
        padding: 25px 0
    }
}
@media screen and (min-width: 320px) {
    .result-area {
        padding: 0
    }
}
@media screen and (min-width: 320px) {
    .panels .block .content {
        padding: 0
    }
}
@media screen and (min-width: 320px) {
    .panels .block .prod {
        width: 82%
    }
}
@media screen and (min-width: 768px) {
    .result-area {
        width: 66.66666667%
    }
}
.pagination li a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

@media screen and (min-width: 768px){
    .fiche .detail-product .text {
        padding-left: 10px;
    }
}

/*
*Intégration du bloc suivez-nous
*/

.newsletter2 {
    border: 1px solid #f5ada3;
    color: #000000;
    font-family: 'HelveticaNeueMedium' !important;
    margin-bottom: 30px;
    position: relative;
    margin-top: 30px;
    padding: 40px 15px 0 15px;
}
@media screen and (min-width: 768px) {
    .newsletter2 {
        padding-top: 50px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 {
        padding-top: 37px;
    }
}
.newsletter2 p {
    margin: 0;
}
.newsletter2 .title {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #f5ada3;
    border-bottom: 20px solid #f5ada3;
    position: absolute;
    width: 75%;
    margin: 0 auto;
    top: -21px;
    left: 12%;
}
.newsletter2 .title .inner-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    position: absolute;
    margin-top: -17px;
    text-align: center;
    width: 100%;
    color: #000000;
}
.newsletter2 .abonne h2 {
    color: #060606;
    font-size: 16px;
    text-align: center;
    margin-bottom: 14px;
    font-weight: 600;
    display: block;
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .newsletter2 .abonne h2 {
        margin-top: 28px;
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .abonne h2 {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
.newsletter2 .abonne p {
    display: inline-block;
    color: #040404;
    font-size: 13px;
    text-align: center;
}
@media screen and (min-width: 360px) {
    .newsletter2 .abonne p {
        width: 100%;
    }
}
@media screen and (min-width: 768px) {
    .newsletter2 .abonne p {
        font-size: 15px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .abonne p {
        font-size: 13px;
    }
}
.newsletter2 .rsociaux {
    text-align: center;
    border-bottom: 1px solid black;
    width: 64%;
    margin: 0 auto;
    padding-bottom: 18px;
}

@media screen and (min-width: 360px) {
    .newsletter2 .rsociaux {
        width: 57%;
    }
}
@media screen and (min-width: 480px) {
    .newsletter2 .rsociaux {
        width: 48%;
    }
}
@media screen and (min-width: 768px) {
    .newsletter2 .rsociaux {
        width: 36%;
        padding-bottom: 30px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .rsociaux {
        width: 61%;
        padding-bottom: 19px;
    }
}
.newsletter2 .rsociaux a {
    display: inline-block;
    display: inline-block;
    margin-right: 15px;
    background: #f5ada3;
    color: #000000;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    vertical-align: middle;
    line-height: 33px;
    font-size: 17px;
}
@media screen and (min-width: 360px) {
    .newsletter2 .rsociaux a {
        width: 38px;
        height: 38px;
        line-height: 38px;
    }
}

@media screen and (min-width: 414px) {
    .newsletter2 .rsociaux a {
        width: 46px;
        height: 46px;
        line-height: 46px;
    }
}
@media screen and (min-width: 568px) {
    .newsletter2 .rsociaux a {
        width: 59px;
        height: 59px;
        line-height: 65px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .rsociaux a {
        width: 37px;
        height: 37px;
        line-height: 37px;
    }
}
@media screen and (min-width: 1280px) {
    .newsletter2 .rsociaux a {
        line-height: 40px;
    }
}
.newsletter2 .rsociaux a i.fa {
    font-weight: 700;
}
@media screen and (min-width: 414px) {
    .newsletter2 .rsociaux a i {
        font-size: 19px;
    }
}
@media screen and (min-width: 568px) {
    .newsletter2 .rsociaux a i {
        font-size: 23px;
    }
}
@media screen and (min-width: 768px) {
    .newsletter2 .rsociaux a i {
        font-size: 25px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .rsociaux a i {
        font-size: 16px;
    }
}
@media screen and (min-width: 1280px) {
    .newsletter2 .rsociaux a i {
        font-size: 18px;
    }
}
.newsletter2 .myForm {
    padding-bottom: 12px;
    margin-top: 10px;
}
@media screen and (min-width: 768px) {
    .newsletter2 .myForm {
        margin-top: 18px;
    }
}
.newsletter2 .myForm fieldset {
    height: 28px;
    line-height: 28px;
    background: #e6e6e6;
    width: 100%;

}
@media screen and (min-width: 768px) {
    .newsletter2 .myForm fieldset {
        height: 31px;
        line-height: 31px;
    }
}
.newsletter2 .myForm input[type="email"] {
    font-family: 'HelveticaNeueMedium';
    font-weight: 400;
    font-size: 13px;
    color: #040404;
    border: 0;
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -o-border-radius: 0;
    -moz-border-radius: 0;
    background: inherit;
    line-height: 22px;
    width: 80%;
    padding: 0 0 0 15px;
}
@media screen and (min-width: 768px) {
    .newsletter2 .myForm input[type="email"] {
        line-height: 28px;
        font-size: 16px;
    }
}
@media screen and (min-width: 1024px) {
    .newsletter2 .myForm input[type="email"] {
        font-size: 13px;
    }
}
.newsletter2 .myForm span.btne {
    display: inline-block;
    float: right;
    padding: 0;
    border: 0;
    line-height: 17px;
    border-left: 1px solid black;
    margin: 5px 0 5px;
    vertical-align: middle;
}
@media screen and (min-width: 768px) {
    .newsletter2 .myForm span.btne {
        line-height: 22px;
    }
}
.newsletter2 .myForm button {
    font-size: 12px;
    cursor: pointer;
    color:#040404;
    background-color: inherit;
    border: 0;
    padding: 0 14px;
}
input::-moz-placeholder, textarea::-moz-placeholder {
    border-color: none;
    border: 0;
    color: #040404;
    font-size: 13px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #040404;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #040404;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #040404;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #040404;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color:    #040404;
}
/*
* Fin intégration du bloc suivez-nous
*/